import useWindowDimensions from "../hooks/useWindowDimensions";
import { useEffect, useState, useRef } from "react";
import '../styles/custom.css'

export default function OverviewLayout({ children }) {
    const [childrenVH, setChildrenVH] = useState();
    const windowHeight = useWindowDimensions().height;
    const headerRef = useRef();
    const footerRef = useRef();

    useEffect(() => {
        var headerVH = (headerRef?.current?.clientHeight / windowHeight) * 100;
        var footerVH = (footerRef?.current?.clientHeight / windowHeight) * 100;
        setChildrenVH(100 - (headerVH + footerVH));
    }, [windowHeight, headerRef?.current?.clientHeight, footerRef?.current?.clientHeight])

    return (
        <>
            <main id="wrapper" >
                <a className="skip-to-content" href="#wrapper">Skip to main content</a>
                <header id="primary-header" role="banner" ref={headerRef}>
                    <div className="center-logo">
                        <a href="/"><img className="companyLogo" data-tenant-branding-logo="true" src="https://aadcdn.msftauthimages.net/dbd5a2dd-m7upushe63t5mro-qoncxx7g0ffd-q0du4joa4qyehc/logintenantbranding/0/bannerlogo?ts=638435352267202102" alt="Company Logo" />
                        </a>
                    </div>
                </header>
                <div style={{ minHeight: childrenVH + "vh" }}>
                    {children}
                </div>
            </main>
        </>
    );
};
