import React, { useContext, useEffect, useState } from "react";
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import Loader, { Types } from "../gdk/Loader";
import ReportHeader from "./ReportHeader";
import ReportOverview from "./Overview/ReportOverview";
import { ReportState } from "./ReportsHelper";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import ReportScorecard from "./Scorecard/ReportScorecard";
import { getOverviewData, getOverviewDataInternal } from "../../api/external-reports";
import Layout from "../../components/Layout";

export default function ReportPage({ substituteAgentInfo }) {
    const user = useContext(UserContext);
    const clientConfiguration = useContext(ClientConfigurationContext);
    const navigate = useNavigate();
    const [view, setView] = useState(ReportState.Overview);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [scorecardExpandedRows, setScorecardExpandedRows] = useState([]);

    useEffect(() => {
        if (!user.authorizations?.canAccessReports || (!clientConfiguration.enableReports && !substituteAgentInfo)) {
            navigate("/");
        }
    });

    async function loadData() {
        const handleSuccess = (response) => {
            response.Status === 3 ? setData(response.Domain) : setData(null);
            setLoading(false);
        }

        const request = {
            ...selectedFilters,
            AgentIds: selectedFilters?.AgentIds ? selectedFilters.AgentIds.map(agent => agent.split('-')[0]) : null
        }

        if (substituteAgentInfo && substituteAgentInfo.id) {
            const substituteAgentRequest = {
                substituteAgentId: substituteAgentInfo.id,
                ...request
            };
            await getOverviewDataInternal(substituteAgentRequest, response => handleSuccess(response))
        } else {
            await getOverviewData(request, response => handleSuccess(response))
        }
    }

    useEffect(() => {
        if (!data) { loadData() }
    });

    function applyFilters() {
        setLoading(true);
        loadData();
    }

    const switchView = () => {
        setView(view === ReportState.Overview ? ReportState.Detailed : ReportState.Overview);
    }

    return (
        <>
            <Layout>
                {(clientConfiguration.enableReports || substituteAgentInfo) && (
                    <div>
                        <ReportHeader
                            title={view}
                            switchView={switchView}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            applyFilters={applyFilters}
                            substituteAgentInfo={substituteAgentInfo || null}
                        />
                        {loading ? <Loader type={Types.Section} /> :
                            <div className="report-body">
                                {view === ReportState.Overview && <ReportOverview
                                    userRole={substituteAgentInfo ? substituteAgentInfo.role : user.role}
                                    data={data}
                                    setData={setData}
                                />}
                                {view === ReportState.Detailed && <ReportScorecard
                                    userRole={substituteAgentInfo ? substituteAgentInfo.role : user.role}
                                    data={data.ScoreCardData}
                                    setData={setData}
                                    expandedRows={scorecardExpandedRows}
                                    setExpandedRows={setScorecardExpandedRows}
                                />}
                            </div>
                        }
                    </div>
                )}
            </Layout>
        </>
    );
}