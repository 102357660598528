import { handleErrors } from "../util/api-utils";

export function initialize(request, successCallback) {
    fetch("api/Onboarding/Initialize", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error);
};
