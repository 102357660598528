import { handleErrors } from "../util/api-utils";

export function getAgencyDefaultsOptions(coverageOptionsRequest, success) {
    fetch("api/AgencyDefaults/Options", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(coverageOptionsRequest)
    })
        .then(handleErrors)
        .then(success)
        .catch(console.error);
}

/**
 * Saves the updated PEAK auto defaults.
 * @param {any} peakAuto The updated PEAK auto defaults.
 * @param {function} successCallback The success callback function.
 * @param {function} finallyCallback The finally callback function.
 */
export function updatePeakAuto(peakAuto, successCallback, finallyCallback) {
    fetch("api/AgencyDefaults/UpdatePeakAuto", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(peakAuto)
    })
        .then(handleErrors)
        .then(successCallback)
        .catch(console.error)
        .finally(finallyCallback);
};