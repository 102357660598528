import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState, useRef } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageHeader from '../PageHeader/PageHeader';
import { initialLoad } from "../../api/initial-load";
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../context/UserContextProvider';
import { GdkToasts } from "@gdk/toasts";
import Loader, { Types } from "../gdk/Loader";
import Layout from "../../components/Layout";

const lobOptionsList = [
    { displayValue: 'Personal Auto', value: 'PEAK' },
    { displayValue: 'Commercial', value: 'COMMERCIAL' },
    //{ displayValue: 'All', value: null }
];

const initialLoadRequestReasonList = [
    { displayValue: 'New to using IVANS downloads' },
    { displayValue: 'Recently switched to a different Agency Management System' },
    { displayValue: 'Did not receive policy downloads' },
    { displayValue: 'Other' }
];

const defaultValues = {
    policySystem: "",
    reason: "",
};

export default function IvansInitialLoad() {
    const user = useContext(UserContext);
    const agencyId = user.agencyId;

    const clientConfiguration = useContext(ClientConfigurationContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (!user.authorizations?.canManageIvansRequests) {
            navigate("/");
        }
    });

    const onSubmit = data => {
        setIsLoading(true); // disable submit button

        initialLoad(
            { agencyId: data.agencyId, reason: data.reason, policySystem: data.policySystem },
            () => {
                toastConfirmationNotification(null, "Initial Load request submitted successfully.");
                handleClear();
            },
            () => {
                toastUpdateNotification(null, "Initial Load request failed.");
            },
            () => {
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        if (!clientConfiguration.enableIvansRequest) {
            return navigate("/");
        }
    }, []);

    const handleClear = () => {
        reset({ ...defaultValues });
    };

    const [updateToastHeader, setUpdateToastHeader] = useState();
    const [updateToastDescription, setUpdateToastDescription] = useState();
    const gdkUpdateToast = useRef(null);

    useEffect(() => {
        gdkUpdateToast.current = new GdkToasts({
            content: "#update-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });
    }, []);

    function toastUpdateNotification(header, description) {
        setUpdateToastHeader(header);
        setUpdateToastDescription(description);
        gdkUpdateToast.current.show();
    }

    const [confirmationToastHeader, setConfirmationToastHeader] = useState();
    const [confirmationToastDescription, setConfirmationToastDescription] = useState();
    const gdkConfirmationToast = useRef(null);

    useEffect(() => {
        gdkConfirmationToast.current = new GdkToasts({
            content: "#confirmation-toast",
            floatTime: "short",
            closeBtn: true,
            baseTimer: true,
        });
    }, []);

    function toastConfirmationNotification(header, description) {
        setConfirmationToastHeader(header);
        setConfirmationToastDescription(description);
        gdkConfirmationToast.current.show();
    }

    return (
        <>
            <Layout>
                <div id="update-toast" className="toasts-container">
                    <div className="toasts toasts--update">
                        <div>
                            <span className="icon-info" />
                        </div>
                        <div role="status">
                            <div className="toasts--heading">{updateToastHeader}</div>
                            <div className="toasts--content">{updateToastDescription}</div>
                        </div>
                        <button className="toasts--close-btn icon-close">
                            <div id="toasts--base-timer" />
                        </button>
                    </div>
                </div>
                <div id="confirmation-toast" className="toasts-container">
                    <div className="toasts toasts--confirmation">
                        <div>
                            <span className="icon-confirmation" />
                        </div>
                        <div role="status">
                            <div className="toasts--heading">{confirmationToastHeader}</div>
                            <div className="toasts--content">{confirmationToastDescription}</div>
                        </div>
                        <button className="toasts--close-btn icon-close">
                            <div id="toasts--base-timer" />
                        </button>
                    </div>
                </div>
                <form id="form-initial-load-admin" onSubmit={handleSubmit(onSubmit)}>
                    <PageHeader
                        header="Ivans Initial Load"
                        showBottomBorder={false}
                        children={
                            <>
                                <p>Request an initial load of all the policies you currently have with GEICO to be sent to Ivans.</p>
                                <a href="/" style={{ textDecoration: "none" }}>
                                    <span className="geico-icon--small icon-chevron-left"></span>
                                    Return to Search
                                </a>
                            </>
                        }
                    />
                    <section>
                        <div className="container margin-top">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-field">
                                        <label htmlFor="agencyId" className="text">
                                            Agency ID
                                        </label>
                                        <input
                                            id="agencyId"
                                            type="text"
                                            size="15"
                                            value={agencyId}
                                            {...register("agencyId", { value: agencyId })}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-top">
                                <div className={"col-lg-6 form-field"}>
                                    <div className={`form-field ${errors.policySystem && "form-field--error"}`}>
                                        <label htmlFor="policy-line-of-business-select-box" className="text">Policy Line of Business</label>
                                        <div className="select-box">
                                            <select
                                                id="policy-line-of-business-select-box"
                                                data-placeholder="Select one"
                                                {...register("policySystem")}>
                                                <option value="">Select one</option>
                                                {lobOptionsList.map((option) =>
                                                    <option key={"opt-" + option.displayValue} value={option.value}>{option.displayValue}</option>
                                                )}
                                            </select>
                                        </div>
                                        {errors.policySystem &&
                                            <span className="form-message">{errors.policySystem.message}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-top">
                                <div className={"col-lg-6 form-field"}>
                                    <div className={`form-field ${errors.reason && "form-field--error"}`}>
                                        <label htmlFor="reason-for-request-select-box" className="text">Reason for Requesting an Initial Load</label>
                                        <div className="select-box">
                                            <select
                                                id="reason-for-request-select-box"
                                                name="reason-for-request-select-box"
                                                defaultValue=""
                                                {...register("reason")}>
                                                <option value="" disabled>Select one</option>
                                                {initialLoadRequestReasonList.map((option) =>
                                                    <option key={"opt-" + option.displayValue} value={option.displayValue}>{option.displayValue}</option>
                                                )}
                                            </select>
                                        </div>
                                        {errors.reason &&
                                            <span className="form-message">{errors.reason.message}</span>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="padding-top btn-center">
                                    {isLoading && <Loader />}
                                    <button id="btnSubmitIvansInitialLoadRequest" type="submit" className="btn btn--primary" disabled={isLoading}>
                                        <span>Submit</span>
                                    </button>
                                    <button id="btnCancelIvansInitialLoadRequest" type="button" className="btn btn--secondary" onClick={handleClear}>
                                        <span>Clear</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </Layout>
        </>
    );
}

const validationSchema = yup.object({
    policySystem: yup.string().test("LOBTest", "Select a line of business", (reason) => {
        return reason !== "";
    }),
    reason: yup.string().test("RequestReasoningTest", "Select a reason for requesting an Initial Load", (reason) => {
        return reason !== "";
    }),
});
