import Layout from "../Layout";
import PageHeader from "../PageHeader/PageHeader";

export default function GeaoKickout() {
  return (
    <Layout>
      <PageHeader header="Unable to Continue Application" icon="icon-alert" />
      <div className="container">
        <div className="margin-top row" style={{ marginTop: "60px" }}>
          <div className="hidden-sm col-md-3">
            <img src="images/pose.png" />
          </div>
          <div className="col-sm-12 col-md-8">
            {/*TODO: Add conditional logic and messages for other kickouts*/}
            {/*E&O Kickout - Display when Onboarding AssessmentType.HasErrorsAndOmmissions + AssessmentStatus.Rejected */}
            <li className="alert-content" style={{ marginTop: "40px" }}>
              <div className="h3">
                An E&O Policy is required to move forward.
              </div>
              <p>
                GEICO For Your Agency requires that agents have an Errors and
                Omissions (E&O) policy or intend to obtain one.
              </p>
              <p>
                We invite you to reapply in the future once you have obtained,
                or intend to obtain, an E&O policy.
              </p>
            </li>
            {/* Captive Agent Kickout - Display when Onboarding AssessmentType.IsCaptiveAgent + AssessmentStatus.Rejected */}
            {
              <li className="alert-content" style={{ marginTop: "40px" }}>
                <div className="h3">
                  Unfortunately, captive agents are ineligible for GEICO for your agency.
                </div>
                <p>
                  GEICO For Your Agency requires that agents independant agencts
                  who can sell policies for multiple carriers. Captive agents
                  selling products for a single company are ineligible.
                </p>
                <p>
                  We invite you to reapply in the future if your status as a
                  agent changes.
                </p>
              </li>
            }
          </div>
        </div>
      </div>
      ;
    </Layout>
  );
}
