import React from 'react';
import AppRouter from './AppRouter';
import './styles/custom.css';
import './styles/match-widths.css';
import './styles/gdk';
import { UserContextProvider } from './context/UserContextProvider';
import { ClientConfigurationContextProvider } from './context/ClientConfigurationContextProvider';

export default function App() {
    return (
        <ClientConfigurationContextProvider>
            <UserContextProvider>
                    <AppRouter />
            </UserContextProvider>
        </ClientConfigurationContextProvider>
    );
}
