import { handleErrors } from "../util/api-utils";

export function getAgentAccess(success) {
    fetch("api/IaConfiguration/RetrieveAgentAccess", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(handleErrors)
        .then(success)
        .catch(console.error);
}