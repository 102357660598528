import PageHeader from "../PageHeader/PageHeader";
import Layout from "../Layout";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { classes } from "../../util/style-utils";
import useYesNoOptions from "../../hooks/useYesNoOptions";
import { v4 } from 'uuid';
import TooltipLabel from "../gdk/TooltipLabel";
import useUsStateOptions from "../../hooks/useUsStateOptions";
import { GdkSocialSecurityNumber } from "@gdk/social-security-number";

export default function GeaoInterview() {
    // get Interview data by? InterviewId? 
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const yesNoOptions = useYesNoOptions();
    const usStateOptions = useUsStateOptions();
    const watchAgencyType = watch("agencyType");
    const watchSoleProprietor = watch("soleProprietorType");
    const socialSecurityNumber = useRef();

    const onSubmit = data => {
        console.log(data);
    };

    return (
        <Layout>
            <PageHeader header="Agency Owner Interview" showBottomBorder={false} />
            <form className="form-onboarding-interview" onSubmit={handleSubmit(onSubmit)}>
                <div className="container agency-interview">
                    <h3 className="text-center margin-top">Agency Information</h3>
                    <br />
                    <div className="row">
                        <div className="col-sm-7">
                            <div
                                className={classes(
                                    "form-field",
                                    errors.agencyName && "form-field--error")}>
                                <TooltipLabel
                                    htmlFor="agencyName"
                                    label="Agency Name"
                                    content="Please enter the agency name listed on your tax forms."
                                />
                                <input
                                    id="agencyName"
                                    type="text"
                                    size="60"
                                    placeholder="Agency Name"
                                    {...register("agencyName")}
                                    maxLength={100}
                                />
                                {errors.agencyName && (
                                    <span className="form-message">{errors.agencyName.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className={classes(
                                "form-field",
                                errors.npn && "form-field--error")}
                            >
                                <label htmlFor="npn" className="text">
                                    Agency NPN
                                </label>
                                <input
                                    id="npn"
                                    className='uppercase-input'
                                    type="text"
                                    size="25"
                                    placeholder="National Producer Number"
                                    {...register("npn")}
                                />
                                {errors.npn && (
                                    <span className="form-message">{errors.npn.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-field">
                                <label htmlFor="select-agency-type" className="text">Agency Type</label>
                                <div className="select-box">
                                    <select id="select-agency-type" name="select-agency-type" {...register("agencyType")}>
                                        <option>Please Select</option>
                                        <option>National Agency</option>
                                        <option>LLC</option>
                                        <option>Independent Insurance Agency</option>
                                        <option>Sole Proprietor</option>
                                    </select>
                                </div>
                                {errors.agencyType && (
                                    <span className="form-message">{errors.agencyType.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* TODO: add onChange to watch agencyType and clear values from soleProprietorType field
                        */}

                        {watchAgencyType === "Sole Proprietor" &&
                            <div className="col-sm-6">
                                <div className="form-field">
                                    <label htmlFor="select-soleProprietorType" className="text">Are you registered as an Agency or as an Individual?</label>
                                    <div className="select-box">
                                        <select id="select-soleProprietorType" name="select-soleProprietorType"{...register("soleProprietorType")}>
                                            <option>Please Select</option>
                                            <option>Agency</option>
                                            <option>Individual</option>
                                        </select>
                                    </div>
                                    {errors.soleProprietorType && (
                                        <span className="form-message">{errors.soleProprietorType.message}</span>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            {watchAgencyType !== "Sole Proprietor" &&
                                <div className={classes("form-field", errors.fein && "form-field--error")}>
                                    <label htmlFor="fein" className="text">
                                        Agency FEIN</label>
                                    <input
                                        id="fein"
                                        type="number"
                                        size="30"
                                        placeholder="FEIN"
                                        {...register("fein", { setValueAs: value => value === "" ? undefined : value })}
                                    />
                                    {errors.fein && (
                                        <span className="form-message">
                                            {errors.fein.message}
                                        </span>
                                    )}
                                </div>
                            }

                            {watchSoleProprietor === "Individual" &&
                                <div id="social-security-number">
                                    <div className={classes("social-security-number-wrapper form-field", errors.ssn && "form-field--error")}>
                                        <TooltipLabel
                                            htmlFor="ssn"
                                            label="Social Security Number"
                                            content={<>At GEICO, we understand the importance of your privacy and security. The information you provide will be kept confidential and secure. It will not be sold or redistributed. To learn more about how we protect your personal information, please review our privacy &amp; security policy: <a href="https://media.geico.com/legal/privacy_policy.htm" target="_blank" className=""> Privacy Policy</a></>}
                                        />
                                        <input id="ssn-input-field" name="ssn-input-field" type="tel" className="social-security-number" autoComplete="off" spellCheck="false" placeholder="___-__-____"
                                            {...register("ssn", { setValueAs: _ => socialSecurityNumber.current?.getSocialSecurityNumber() })} />
                                        <input type="password" id="ssn-input-value" name="ssn-input-value" className="secureInput" maxLength="11" autoComplete="off" spellCheck="false" aria-hidden="true" />
                                        <a className="social-security-show-hide" href="#"><span className="icon-hide" aria-label="Hide"></span></a>
                                        {errors.ssn && (
                                            <span className="form-message">
                                                {errors.ssn.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            }

                            {watchSoleProprietor === "Agency" &&
                                <div className={classes("form-field", errors.fein && "form-field--error")}>
                                    <label htmlFor="fein" className="text">
                                        Agency FEIN</label>
                                    <input
                                        id="fein"
                                        type="number"
                                        size="30"
                                        placeholder="FEIN"
                                        {...register("fein", { setValueAs: value => value === "" ? undefined : value })}
                                    />
                                    {errors.fein && (
                                        <span className="form-message">
                                            {errors.fein.message}
                                        </span>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    <fieldset role="radiogroup" className={classes("form-field radio-button-group", errors.directCommissions && "form-field--error")}>
                        <legend>
                            Does this agency receive commission payments directly from carriers?
                        </legend>
                        <div className="radio-button-wrapper col-2">
                            {yesNoOptions?.map((option) => {
                                var id = `${option.label}-radio-${v4()}`;
                                return (
                                    <div key={id}>
                                        <input
                                            id={id}
                                            type="radio"
                                            value={option.value}
                                            {...register("directCommissions")}
                                        />
                                        <label className="radio" htmlFor={id}>
                                            <span>{option.label}</span>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {errors.directCommissions && (
                            <span className="form-message">
                                {errors.directCommissions.message}
                            </span>
                        )}
                    </fieldset>
                    <div className="row">
                        <div className="col-sm-7">
                            <div
                                className={classes(
                                    "form-field",
                                    errors.agencyWebsite && "form-field--error")}>
                                <label htmlFor="agencyWebsite" className="text">
                                    Agency Website
                                </label>
                                <input
                                    id="agencyWebsite"
                                    type="text"
                                    size="60"
                                    placeholder="Website"
                                    {...register("agencyWebsite")}
                                    maxLength={100}
                                />
                                {errors.agencyWebsite && (
                                    <span className="form-message">{errors.agencyWebsite.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className={classes("form-field",
                                errors.agencyFacebook && "form-field--error")}>
                                <label htmlFor="agencyFacebook" className="text">
                                    Agency Facebook Handle
                                </label>
                                <input
                                    id="agencyFacebook"
                                    type="text"
                                    size="40"
                                    placeholder="Facebook Handle"
                                    {...register("agencyFacebook")}
                                    maxLength={50}
                                />
                                {errors.agencyFacebook && (
                                    <span className="form-message">{errors.agencyFacebook.message}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={classes("form-field",
                                errors.agencyInstagram && "form-field--error")}>
                                <label htmlFor="agencyInstagram" className="text">
                                    Agency Instagram Handle
                                </label>
                                <input
                                    id="agencyInstagram"
                                    type="text"
                                    size="40"
                                    placeholder="Instagram Handle"
                                    {...register("agencyInstagram")}
                                    maxLength={50}
                                />
                                {errors.agencyInstagram && (
                                    <span className="form-message">{errors.agencyInstagram.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container business-address">
                    <h3 className="text-center margin-top">Business Address</h3>
                </div>
                <div className="container mailing-address">
                    <h3 className="text-center margin-top">Mailing Address</h3>
                </div>
                <div className="container agency-financial-info">
                    <h3 className="text-center margin-top">Agency Financial Information</h3>
                </div>
                <div className="container premium-profile">
                    <h3 className="text-center margin-top">Agency Premium Profile</h3>
                </div>
                <div className="container carrier-appointments">
                    <h3 className="text-center margin-top">Carrier Appointments</h3>
                </div>
                <div className="container employee-details">
                    <h3 className="text-center margin-top">Employee Details</h3>
                </div>
                <div className="container agency-management-system">
                    <h3 className="text-center margin-top">Agency Management System Details</h3>
                </div>
                <div className="container agency-marketing-info">
                    <h3 className="text-center margin-top">Agency Marketing Information</h3>
                </div>
            </form>
        </Layout >
    );
}