import React from "react";
import Loader, {Types} from "../../gdk/Loader";
import '../Reports.css';
import {scorecardHeaders, scorecardDataFields, getScorecardOrderedGroupingKeys, backgroundColors, dataFieldsGroups} from "./ReportScorecardConstants";

export default function ReportScorecard({ userRole, data, loading, expandedRows, setExpandedRows }) {
    const handleExpandClick = (id) => {
        setExpandedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const orderedGroupingKeys = getScorecardOrderedGroupingKeys(userRole);

    function groupDataByKey(data, keyToGroupBy){
        return data.reduce((acc, item) => {
            const groupingValue = keyToGroupBy === "ReportingDate" 
                ? new Date(item[keyToGroupBy]).toLocaleDateString() 
                : item[keyToGroupBy];
    
            if (!acc[groupingValue]) {
                acc[groupingValue] = { sum: {}, data: [], count: {} };
            }

            scorecardDataFields.filter(field => !field.includes("closure")).forEach(key => {
                acc[groupingValue].sum[key] = (acc[groupingValue].sum[key] || 0) + item[key];
            });

            dataFieldsGroups.forEach(group => {
                const opportunities = acc[groupingValue]?.sum[group + "_opportunities"];
                if (opportunities > 0) {
                    acc[groupingValue].sum[group + "_closure"] = acc[groupingValue].sum[group + "_new_business"] / opportunities;
                }
            });
            
            acc[groupingValue].data.push(item);
            return acc;
        }, {});
    }

    const formatNumberForDisplay = (field, value) => {
        if (value === undefined || value === 0) return '';

        const fieldType = field.split("_").pop();
        let prefix = fieldType === 'premium' ? '$' : '';
        let suffix = fieldType === 'closure' ? '%' : '';

        let number = fieldType === 'closure' ? (value * 100).toFixed(1) : value.toFixed(2);
        number = parseFloat(number).toLocaleString();
    
        return `${prefix}${number}${suffix}`;
    };

    function Row({ rowId, header, summedData, expansionData, level, currentMaxLevel }){
        const indentation = { paddingLeft: `${level * 10}px`};
        const backgroundColor = (level === currentMaxLevel && level > 1) ? { backgroundColor: backgroundColors[level]} : {};
    
        return (
            <>
                <tr>
                    <td style={{...indentation}}>
                        {expansionData ? (
                            <>
                            <span
                                onClick={() => handleExpandClick(rowId)}
                                style={{ cursor: "pointer", marginRight: "5px" }}
                            >
                                {expandedRows.includes(rowId) 
                                    ? <span className="icon-collapse"/>
                                    : <span className="icon-expand"/>}
                            </span>
                            <b>{header}</b>
                            </>
                        ) : (
                            <div style={{paddingLeft: "20px"}}><b>{header}</b></div>
                        )}
                    </td>
                    {scorecardDataFields.map(field => (
                        <td key={field} style={backgroundColor}>
                            {formatNumberForDisplay(field, summedData[field])}
                        </td>
                    ))}
                </tr>
                {expandedRows.includes(rowId) &&
                    expansionData && 
                    Object.entries(groupDataByKey(expansionData, orderedGroupingKeys[level])).map(([groupingKey, {sum, data}]) => (
                        <Row
                            key={groupingKey}
                            rowId={rowId + "-" + groupingKey}
                            header={groupingKey}
                            summedData={sum}
                            handleExpandClick={handleExpandClick}
                            level={level + 1}
                            currentMaxLevel={currentMaxLevel + 1}
                            expansionData={level === orderedGroupingKeys.length - 1 ? null : data}
                        />
                    ))
                }
            </>
        );
    };
    
    return ( loading ? <Loader type={Types.Section} /> : !data ? <div className='no-data-container'>No data available</div> :
        <div className="data-table scorecard printable">
            <table className="table">
                <thead>
                    <tr>
                        <th>{userRole === "IA Principal" ? "Branch" : "Date"}</th>
                        {scorecardHeaders.groups.map(group => (
                            scorecardHeaders.metrics.map(metric => (
                                <th>{group}<br/>{metric}</th>
                            ))
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(groupDataByKey(data, orderedGroupingKeys[0])).map(([groupingKey, {sum, data}]) => (
                       <Row
                            key={groupingKey}
                            rowId={"row-"+groupingKey}
                            header={groupingKey}
                            summedData={sum}
                            expansionData={data}
                            level={1}
                            currentMaxLevel={1}
                       />
                    ))}
                </tbody>
            </table>
        </div>
    );
}