import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageHeader from '../PageHeader/PageHeader';
import Loader, { Types } from "../gdk/Loader";
import { classes } from "../../util/style-utils";
import { UserContext } from "../../context/UserContextProvider";
import { ClientConfigurationContext } from "../../context/ClientConfigurationContextProvider";
import { getAgencyDefaultsOptions, updatePeakAuto } from "../../api/agency-defaults";
import { getAgencyProfile } from "../../api/agency-profile";
import Layout from "../../components/Layout";

export default function AgencyDefaults() {
    const clientConfiguration = useContext(ClientConfigurationContext);
    const user = useContext(UserContext);
    const [agencyProfile, setAgencyProfile] = useState();

    useEffect(() => getAgencyProfile(user?.agencyId, agencyProfile => setAgencyProfile(agencyProfile)), []);

    const savedPeakAutoDefaults = agencyProfile?.defaults?.peakAuto;
    const [options, setOptions] = useState();
    const [enableButtons, setEnableButtons] = useState(true);
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
    });

    useEffect(() => {
        if (agencyProfile) {
            const getCompanyCode = (stateCode) => {
                if (stateCode === "TX") {
                    return "T7";
                } else {
                    return "GB";
                }
            };

            const stateCode = agencyProfile?.businessAddress?.state;
            const companyCode = getCompanyCode(stateCode);

            getAgencyDefaultsOptions({ StateCode: stateCode, CompanyCode: companyCode }, response => {
                setOptions(response.agencyDefaultsOptions);
            });
        }
    }, [agencyProfile]);

    const onSubmit = (values) => {
        if (!values.errors) {
            setEnableButtons(false);

            const updatedPeakAuto = {
                ...agencyProfile.defaults?.peakAuto,
                propertyDamageLiabilityLimit: options.find(option => option.mnemonic === "PD" && option.limitShortDescription === values.propertyDamageLiability),
                bodilyInjuryLiabilityLimit: options.find(option => option.mnemonic === "BI" && option.limitShortDescription === values.bodilyInjuryLiability),
                collisionDeductible: options.find(option => option.mnemonic === "COLL" && option.deductibleShortDescription === values.collisionDeductible),
                comprehensiveDeductible: options.find(option => option.mnemonic === "COMP" && option.deductibleShortDescription === values.comprehensiveDeductible),
                medicalPaymentLimit: options.find(option => option.mnemonic === "MED" && option.limitShortDescription === values.medicalPaymentLimit),
                rentalLimit: options.find(option => option.mnemonic === "RR" && option.limitShortDescription === values.rentalLimit),
                roadsideAssistance: options.find(option => option.mnemonic === "ERS" && option.limitShortDescription === values.roadsideAssistance),
                pipLimits: options.find(option => option.mnemonic === "PIP" && option.limitShortDescription === values.pipLimits),
                hiredAuto: values.hiredAuto,
                anyAuto: values.anyAuto,
                blanketAiWos: values.blanketAiWos,
                paymentMethodEFT: values.paymentMethodEFT,
                paymentPlan: values.paymentPlan
            };

            var updatedAgencyProfile = {
                ...agencyProfile,
                defaults: {
                    ...agencyProfile.defaults,
                    peakAuto: updatedPeakAuto
                }
            };

            updatePeakAuto(updatedPeakAuto,
                () => {
                    setAgencyProfile(updatedAgencyProfile);
                },
                () => {
                    setEnableButtons(true);
                });
        }
    };

    useEffect(() => {
        if (!clientConfiguration.enableAgencyDefaults || !user.authorizations?.canSetAgencyDefaults) {
            navigate("/");
        }
    }, [clientConfiguration, navigate]);

    if (!clientConfiguration.enableAgencyDefaults || !user.authorizations?.canSetAgencyDefaults) {
        return <Loader type={Types.Section} />
    }

    return <>
        <Layout>
            <PageHeader
                header="Agency Defaults"
                showBottomBorder={true}
                children={<p>Adjust your default coverages.</p>}
                icon="icon-coverage"
            />

            <div className="container margin-top">
                {(!agencyProfile || !options) && <Loader />}
                {agencyProfile && options &&
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className={classes("form-field", errors.lineOfBusiness && "form-field--error")}>
                            <label htmlFor="line-of-business" className="text">Line of Business</label>
                            <div className="select-box">
                                <select id="line-of-business" {...register("lineOfBusiness")}>
                                    <option value="ppa" key="ppa" >Personal Auto</option>
                                </select>
                            </div>
                            {errors.lineOfBusiness &&
                                <span className="form-message">{errors.lineOfBusiness.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.propertyDamageLiability && "form-field--error")}>
                            <label htmlFor="property-damage-liability" className="text">Property Damage Liability</label>
                            <div className="select-box">
                                <select id="property-damage-liability" {...register("propertyDamageLiability", { value: savedPeakAutoDefaults?.propertyDamageLiabilityLimit?.LimitShortDescription || savedPeakAutoDefaults?.propertyDamageLiabilityLimit?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "PD").map(o =>
                                        <option value={o.limitShortDescription} key={o.limitShortDescription}>{o.limitDescription}</option>
                                    )}
                                </select>
                            </div>
                            {errors.propertyDamageLiability &&
                                <span className="form-message">{errors.propertyDamageLiability.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.bodilyInjuryLiability && "form-field--error")}>
                            <label htmlFor="bodily-injury-liability" className="text">Bodily Injury Liability</label>
                            <div className="select-box">
                                <select id="bodily-injury-liability" {...register("bodilyInjuryLiability", { value: savedPeakAutoDefaults?.bodilyInjuryLiabilityLimit?.LimitShortDescription || savedPeakAutoDefaults?.bodilyInjuryLiabilityLimit?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "BI").map(o =>
                                        <option
                                            value={o.limitShortDescription}
                                            key={o.limitShortDescription}
                                        >
                                            {o.limitDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.bodilyInjuryLiability &&
                                <span className="form-message">{errors.bodilyInjuryLiability.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.collisionDeductible && "form-field--error")}>
                            <label htmlFor="collision-deductible" className="text">Collision Deductible</label>
                            <div className="select-box">
                                <select id="collision-deductible" {...register("collisionDeductible", { value: savedPeakAutoDefaults?.collisionDeductible?.DeductibleShortDescription || savedPeakAutoDefaults?.collisionDeductible?.deductibleShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "COLL").map(o =>
                                        <option
                                            value={o.deductibleShortDescription}
                                            key={o.deductibleShortDescription}
                                        >
                                            {o.deductibleDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.collisionDeductible &&
                                <span className="form-message">{errors.collisionDeductible.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.comprehensiveDeductible && "form-field--error")}>
                            <label htmlFor="comprehensive-deductible" className="text">Comprehensive Deductible</label>
                            <div className="select-box">
                                <select id="comprehensive-deductible" {...register("comprehensiveDeductible", { value: savedPeakAutoDefaults?.comprehensiveDeductible?.DeductibleShortDescription || savedPeakAutoDefaults?.comprehensiveDeductible?.deductibleShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "COMP").map(o =>
                                        <option
                                            value={o.deductibleShortDescription}
                                            key={o.deductibleShortDescription}
                                        >
                                            {o.deductibleDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.comprehensiveDeductible &&
                                <span className="form-message">{errors.comprehensiveDeductible.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.medicalPaymentLimit && "form-field--error")}>
                            <label htmlFor="medical-payment-limit" className="text">Medical Payment Limit</label>
                            <div className="select-box">
                                <select id="medical-payment-limit" {...register("medicalPaymentLimit", { value: savedPeakAutoDefaults?.medicalPaymentLimit?.LimitShortDescription || savedPeakAutoDefaults?.medicalPaymentLimit?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "MED").map(o =>
                                        <option
                                            value={o.limitShortDescription}
                                            key={o.limitShortDescription}
                                        >
                                            {o.limitDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.medicalPaymentLimit &&
                                <span className="form-message">{errors.medicalPaymentLimit.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.rentalLimit && "form-field--error")}>
                            <label htmlFor="rental-limit" className="text">Rental Limit</label>
                            <div className="select-box">
                                <select id="rental-limit" {...register("rentalLimit", { value: savedPeakAutoDefaults?.rentalLimit?.LimitShortDescription || savedPeakAutoDefaults?.rentalLimit?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "RR").map(o =>
                                        <option
                                            value={o.limitShortDescription}
                                            key={o.limitShortDescription}
                                        >
                                            {o.limitDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.rentalLimit &&
                                <span className="form-message">{errors.rentalLimit.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.roadsideAssistance && "form-field--error")}>
                            <label htmlFor="roadside-assistance" className="text">Roadside Assistance</label>
                            <div className="select-box">
                                <select id="roadside-assistance" {...register("roadsideAssistance", { value: savedPeakAutoDefaults?.roadsideAssistance?.LimitShortDescription || savedPeakAutoDefaults?.roadsideAssistance?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "ERS").map(o =>
                                        <option
                                            value={o.limitShortDescription}
                                            key={o.limitShortDescription}
                                        >
                                            {o.limitDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.roadsideAssistance &&
                                <span className="form-message">{errors.roadsideAssistance.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.pipLimits && "form-field--error")}>
                            <label htmlFor="pip-limits" className="text">PIP Limits</label>
                            <div className="select-box">
                                <select id="pip-limits" {...register("pipLimits", { value: savedPeakAutoDefaults?.pipLimits?.LimitShortDescription || savedPeakAutoDefaults?.pipLimits?.limitShortDescription })}>
                                    <option value="" key="">Not Defaulted</option>
                                    {options?.filter(o => o.mnemonic === "PIP").map(o =>
                                        <option
                                            value={o.limitShortDescription}
                                            key={o.limitShortDescription}
                                        >
                                            {o.limitDescription}
                                        </option>
                                    )}
                                </select>
                            </div>
                            {errors.pipLimits &&
                                <span className="form-message">{errors.pipLimits.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.hiredAuto && "form-field--error")}>
                            <label htmlFor="hired-auto" className="text">Hired Auto</label>
                            <div className="select-box">
                                <select id="hired-auto" {...register("hiredAuto", { value: savedPeakAutoDefaults?.hiredAuto })}>
                                    <option value="" key="">Not Defaulted</option>
                                    <option value="on" key="on">On</option>
                                    <option value="off" key="off">Off</option>
                                </select>
                            </div>
                            {errors.hiredAuto &&
                                <span className="form-message">{errors.hiredAuto.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.anyAuto && "form-field--error")}>
                            <label htmlFor="any-auto" className="text">Any Auto</label>
                            <div className="select-box">
                                <select id="any-auto" {...register("anyAuto", { value: savedPeakAutoDefaults?.anyAuto })}>
                                    <option value="" key="">Not Defaulted</option>
                                    <option value="on" key="on">On</option>
                                    <option value="off" key="off">Off</option>
                                </select>
                            </div>
                            {errors.anyAuto &&
                                <span className="form-message">{errors.anyAuto.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.blanketAiWos && "form-field--error")}>
                            <label htmlFor="blanket-ai-wos" className="text">Blanket AI/WOS</label>
                            <div className="select-box">
                                <select id="blanket-ai-wos" {...register("blanketAiWos", { value: savedPeakAutoDefaults?.blanketAiWos })}>
                                    <option value="" key="">Not Defaulted</option>
                                    <option value="on" key="on">On</option>
                                    <option value="off" key="off">Off</option>
                                </select>
                            </div>
                            {errors.blanketAiWos &&
                                <span className="form-message">{errors.blanketAiWos.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.paymentMethodEFT && "form-field--error")}>
                            <label htmlFor="payment-method-eft" className="text">Payment Method EFT</label>
                            <div className="select-box">
                                <select id="payment-method-eft" {...register("paymentMethodEFT", { value: savedPeakAutoDefaults?.paymentMethodEFT })}>
                                    <option value="" key="">Not Defaulted</option>
                                    <option value="yes" key="yes">Yes</option>
                                    <option value="no" key="no">No</option>
                                </select>
                            </div>
                            {errors.paymentMethodEFT &&
                                <span className="form-message">{errors.paymentMethodEFT.message}</span>
                            }
                        </div>

                        <div className={classes("form-field", errors.paymentPlan && "form-field--error")}>
                            <label htmlFor="payment-plan" className="text">Payment Plan</label>
                            <div className="select-box">
                                <select id="payment-plan" {...register("paymentPlan", { value: savedPeakAutoDefaults?.paymentPlan })}>
                                    <option value="" key="">Not Defaulted</option>
                                    <option value="paid" key="paid">Paid in Full</option>
                                    <option value="lowest" key="lowest">Lowest Pay Down</option>
                                </select>
                            </div>
                            {errors.paymentPlan &&
                                <span className="form-message">{errors.paymentPlan.message}</span>
                            }
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <button
                                    type="submit"
                                    className="btn btn--primary btn--full-mobile btn--pull-right"
                                    disabled={!enableButtons}
                                >
                                    <span>Save</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                                    onClick={() => reset()}
                                    disabled={!enableButtons}
                                >
                                    <span>Reset</span>
                                </button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </Layout>
    </>;
}