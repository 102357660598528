import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useRef } from "react";
import { GdkDatePicker } from "@gdk/datepicker";
import $ from "jquery";
import FileUploadState from "../FileUpload/FileUploadState";
import { updateErrorsAndOmissions } from "../../api/agency-profile";

export default function ErrorsAndOmissionsForm({
    agencyProfile,
    setAgencyProfile = () => { },
    expandableForm,
    fileUploadState,
    toastUpdateNotification = () => { },
    toastConfirmationNotification = () => { }
}) {
    const { register, handleSubmit, setValue, clearErrors, formState: { errors }, reset } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        reset();
    }, [agencyProfile]);

    const onSubmit = (values) => {
        if (!values.errors) {
            const updatedAgencyProfile = {
                ...agencyProfile,
                eoInfo: {
                    ...agencyProfile.eoInfo,
                    expiration: new Date($("#expiration").datepicker("getDate")).toISOString().split('T')[0],
                    effective: new Date($("#effective").datepicker("getDate")).toISOString().split('T')[0],
                    carrier: values.carrier,
                    policyNumber: values.policyNumber,
                    incidentAmount: values.incidentAmount?.toString(),
                    totalAmount: values.totalAmount?.toString(),
                    eoCoversPrincipalAgency: values.policyCoverage ? "Y" : "N",
                }
            };

            updateErrorsAndOmissions(updatedAgencyProfile,
                () => {
                    setAgencyProfile(updatedAgencyProfile);
                    toastConfirmationNotification(null, "Your E&O policy info has been updated.");
                    expandableForm?.closeExpandedForm(document.getElementById("eo-info-form-li"));
                });
        }
    };

    const onCancel = () => {
        reset();
        toastUpdateNotification(null, "No update was made to your E&O policy info.");
    }

    const effectiveDatePickerRef = useRef();

    useEffect(() => {
        effectiveDatePickerRef.current = new GdkDatePicker({
            content: "#effective",
            monthYearDropdowns: true,
        });
    }, []);

    const expirationDatePickerRef = useRef();

    useEffect(() => {
        expirationDatePickerRef.current = new GdkDatePicker({
            content: "#expiration",
            monthYearDropdowns: true,
        });
    }, []);

    useEffect(() => {
        if (fileUploadState === FileUploadState.Complete) {
            clearErrors("fileUploadState");
        }
        setValue("fileUploadState", fileUploadState);
    }, [fileUploadState])

    const policyCoverageOptions = PolicyCoverageOptions();

    for (const option of policyCoverageOptions) {
        option.selected = agencyProfile?.eoInfo?.eoCoversPrincipalAgency === option.value;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`form-field large-text-input ${errors.carrier && `form-field--error`}`}>
                <label htmlFor="carrier" className="text">Carrier</label>
                <input id="carrier" type="text" size="30" {...register("carrier", { value: agencyProfile?.eoInfo?.carrier })} />
                {errors.carrier &&
                    <span className="form-message">{errors.carrier.message}</span>
                }
            </div>

            <div className={`form-field ${errors.policyNumber && `form-field--error`}`}>
                <label htmlFor="policy-number" className="text">Policy Number</label>
                <input id="policy-number" type="text" size="15" {...register("policyNumber", { value: agencyProfile?.eoInfo?.policyNumber })} />
                {errors.policyNumber &&
                    <span className="form-message">{errors.policyNumber.message}</span>
                }
            </div>

            <div className={`form-field ${errors.effective && `form-field--error`}`}>
                <label htmlFor="effective" className="text">Effective Date</label>
                <input
                    id="effective"
                    className="date date-datepicker"
                    type="tel"
                    placeholder="MM/DD/YYYY"
                    aria-label="Date. Please enter 2 digit month, 2 digit day, and 4 digit year"
                    {...register("effective", { valueAsDate: true, value: new Date(agencyProfile?.eoInfo?.effective).toLocaleDateString("en-US", { timeZone: 'UTC' }) })}
                />
                {errors.effective &&
                    <span className="form-message">{errors.effective.message}</span>
                }
            </div>

            <div className={`form-field ${errors.expiration && `form-field--error`}`}>
                <label htmlFor="effective" className="text">Expiration Date</label>
                <input
                    id="expiration"
                    className="date date-datepicker"
                    type="tel"
                    placeholder="MM/DD/YYYY"
                    aria-label="Date. Please enter 2 digit month, 2 digit day, and 4 digit year"
                    {...register("expiration", { valueAsDate: true, value: new Date(agencyProfile?.eoInfo?.expiration).toLocaleDateString("en-US", { timeZone: 'UTC' }) })}
                />
                {errors.expiration &&
                    <span className="form-message">{errors.expiration.message}</span>
                }
            </div>

            <div className={`form-field ${errors.incidentAmount && `form-field--error`}`}>
                <label htmlFor="incident-amount" className="text">Limit per Claim</label>
                <input id="incident-amount" type="text" size="15" {...register("incidentAmount", { value: agencyProfile?.eoInfo?.incidentAmount })} />
                {errors.incidentAmount &&
                    <span className="form-message">{errors.incidentAmount.message}</span>
                }
            </div>

            <div className={`form-field ${errors.totalAmount && `form-field--error`}`}>
                <label htmlFor="total-amount" className="text">Aggregate Claim Amount</label>
                <input id="total-amount" type="text" size="15" {...register("totalAmount", { value: agencyProfile?.eoInfo?.totalAmount })} />
                {errors.totalAmount &&
                    <span className="form-message">{errors.totalAmount.message}</span>
                }
            </div>

            <fieldset role="radiogroup" className={`form-field radio-button-group ${errors.policyCoverage && `form-field--error`}`}>
                <legend>Policy covers the Agency and Principal?</legend>
                <div className="radio-button-wrapper col-2">
                    {policyCoverageOptions?.map((option) => (
                        <div key={`${option.value ? "yes" : "no"}-radio`}>
                            <input
                                id={`${option.value ? "yes" : "no"}-radio`}
                                type="radio"
                                value={option.value}
                                defaultChecked={(agencyProfile?.eoInfo?.eoCoversPrincipalAgency === "Y" ? true : false) === option.value}
                                {...register("policyCoverage")}
                            />
                            <label
                                className="radio"
                                htmlFor={`${option.value ? "yes" : "no"}-radio`}>
                                <span>{option.label}</span>
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>

            <div className="row">
                <div className="col-sm-12">
                    <button type="submit"
                        className="btn btn--primary btn--full-mobile btn--pull-right">
                        <span>Save</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn--destructive btn--full-mobile btn--pull-right expandable-form-cancel-btn"
                        onClick={() => onCancel()}
                    >
                        <span>Cancel</span>
                    </button>
                </div>
                <div className={`form-field ${errors.fileUploadState && `form-field--error`} pull-right`}>
                    {errors.fileUploadState &&
                        <span className="form-message">{errors.fileUploadState.message}</span>
                    }
                </div>
            </div>
        </form>
    );
};

const validationSchema = yup.object({
    carrier: yup.string().required("Required."),
    policyNumber: yup.string().required("Required."),
    effective: yup.date().required("Required.").typeError("Must be a date."),
    expiration: yup.date().required("Required.").typeError("Must be a date."),
    incidentAmount: yup.number().min(500000, "Must be at least $500,000").required("Required.").typeError("Must be a number."),
    totalAmount: yup.number().min(500000, "Must be at least $500,000").required("Required.").typeError("Must be a number."),
    policyCoverage: yup.bool().required("Required."),
    fileUploadState: yup.string().test("Upload state", "Please upload your new E&O policy document.", (fileUploadState) => {
        return fileUploadState === FileUploadState.Complete;
    })
});

const PolicyCoverageOptions = () => [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

export { PolicyCoverageOptions };
