import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import ReportPage from "./ReportPage";
import Layout from "../../components/Layout";

export default function SalesLeaderReportPage() {
    const user = useContext(UserContext);
    const navigate = useNavigate();

    const substituteAgentInfo = {
        id: localStorage.getItem("substituteAgentId") || null,
        name: localStorage.getItem("substituteAgentName") || null,
        role: localStorage.getItem("substituteAgentProfile") || null
    }

    useEffect(() => {
        if (!user.authorizations?.canAccessReports || !substituteAgentInfo.id) {
            navigate("/dashboard");
        }
    });

    return (
        <Layout>
            <ReportPage substituteAgentInfo={substituteAgentInfo} />
        </Layout>
    );
}