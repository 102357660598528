import PageHeader from "../PageHeader/PageHeader";
import { GdkCheckpoint } from "@gdk/checkpoint";
import React, { useEffect } from "react";
import OverviewLayout from "../OverviewLayout";

export default function GeaoOverview() {

    useEffect(() => {
        new GdkCheckpoint({
            content: `#checkpoint1`,
            data: [
                {
                    title: "[Submit Application]",
                    status: "in process"
                },
                {
                    title: "Complete Onboarding"
                },
                {
                    title: "Background Check"
                },
                {
                    title: "Start Selling with GEICO"
                }
            ],
            animated: true
        });
    }, []);

    return <>
        <OverviewLayout>
            <div className="font--center">
                <PageHeader header="Welcome to GEICO For Your Agency." backgroundColor="#FFFFFF"
                    children={"Thanks for considering becoming an agent with GEICO!"} showBottomBorder={false}>
                </PageHeader>
                <div className="container margin-top">
                    <div className="row">
                        <div className="checkpoint col-md-6 overview-block" backgroundColor="#7094f0  important!" id="checkpoint1">
                            <p className="checkpoint checkpoint-heading margin-top">What's Next?</p>
                            <ul className="checkpoint-content"></ul></div>
                        <div className="col-md-6">
                            <p className="header-text">Have these details ready:</p>
                            <ul className="list list--unordered">
                                <li>
                                    Employer Identification Number (EIN) or Social Security Number (SSN)
                                </li>
                                <li>
                                    National Producer Number (NPN)
                                </li>
                            </ul>
                            <p className="header-text">GEICO For Your Agency qualifications:</p>
                            <ul className="list list--unordered">
                                <li>
                                    Operate as an independent agent
                                </li>
                                <li>
                                    Have an Errors & Omissions (E&O) Policy or intend to obtain one
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    className="btn btn--primary btn--full-mobile"
                >
                    <span>
                        [Start Application]
                    </span>
                </button>
            </div>
        </OverviewLayout>
    </>
}
