import ProfileManagement from "./components/ProfileManagement/ProfileManagement";
import Home from "./components/Home/Home";
import AgencyDefaults from "./components/AgencyDefaults/AgencyDefaults";
import IvansPolicySync from "./components/IvansPolicySync/IvansPolicySync";
import IvansInitialLoad from "./components/IvansInitialLoad/IvansInitialLoad";
import ReportPage from "./components/Reports/ReportPage";
import SalesLeaderReportPage from "./components/Reports/SalesLeaderReportPage";
import Dashboard from "./components/Dashboard/SalesLeaderView";
import Error from "./components/Error";
import GeaoOverview from "./components/GeaoOnboarding/GeaoOverview";
import GeaoKickout from "./components/GeaoOnboarding/GeaoKickout";
import GeaoInterview from "./components/GeaoOnboarding/GeaoInterview";
import { Route, Routes } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./context/UserContextProvider";
import SessionExpired from "./components/SessionExpired/SessionExpired";
import { getAgentAccess } from "./api/agent-access";
import GeaoPreOnboarding from "./components/GeaoOnboarding/GeaoPreOnboarding";

const AppRoutes = [
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.agencyIdentityUiEnabled,
        index: true,
        element: <Home />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.agencyIdentityUiEnabled,
        path: '/profiles',
        element: <ProfileManagement />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.agencyIdentityUiEnabled,
        path: '/defaults',
        element: <AgencyDefaults />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.agencyIdentityUiEnabled,
        path: '/policy-sync',
        element: <IvansPolicySync />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.agencyIdentityUiEnabled,
        path: '/initial-load',
        element: <IvansInitialLoad />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.externalReportsEnabled,
        path: '/reports',
        element: <ReportPage />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.externalReportsEnabled,
        path: '/view-reports',
        element: <SalesLeaderReportPage />
    },
    {
        authorize: (user, access) => user.authorizations?.canViewAgencyIdentity && access?.externalReportsEnabled,
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        authorize: _ => true,
        path: '/onboarding',
        element: <GeaoPreOnboarding />
    },
    {
        authorize: _ => true,
        path: '/onboarding-overview',
        element: <GeaoOverview />
    },
    {
        authorize: _ => true,
        path: '/onboarding-kickout',
        element: <GeaoKickout />
    },
    {
        authorize: _ => true,
        path: '/onboarding-interview',
        element: <GeaoInterview />
    },
    {
        authorize: (user, access) => user.sessionExpired || user.loginRequired,
        path: "*",
        element: <SessionExpired />
    },
    {
        authorize: (user, access) => !(user.sessionExpired || user.loginRequired),
        path: "*",
        element: <Error />
    }
];

export default function AppRouter() {
    const user = useContext(UserContext);
    const [access, setAccess] = useState(null);

    useEffect(() => {
        getAgentAccess(response => { setAccess(response) });
    }, [])

    return (access || user.loginRequired || user.sessionExpired) &&
        <Routes>
            {AppRoutes
                .filter(route => route.authorize(user, access))
                .map((route, index) => <Route key={index} {...route} />)}
        </Routes>
}
